import React, { useState, useEffect, useContext } from "react";
import { Button, Autocomplete, Avatar, Typography, Box, Grid, Checkbox, TextField, Snackbar, FormGroup, FormControlLabel } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Loading from '../../components/common/Loading';
import { AuthContext } from "../../contexts/auth";
import { useLocation, useNavigate } from 'react-router-dom';
import { IPermission } from "../../contexts/interface/IPermission";
import { ICadastroUsuario } from '../../interfaces/ICadastroUsuario';
import { Modal } from "antd";

interface ILiderUsuario {
    id: number;
    username: string;
}

interface IAutocompleteOption {
    label: string;
    id: string;
}

function CadastroUsuarioPage() {
    const location = useLocation();
    const currentUser = location.state?.currentUser;
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmaSenha, setConfirmaSenha] = useState("");
    const [acessoGDocs, setAcessoGDocs] = useState(true);
    const [ehLiderTime, setEhLiderTime] = useState(false);
    const [atualizarADBaseGdocs, setAtualizarADBaseGdocs] = useState(false);
    const [ativo, setAtivo] = useState(true);
    const [ramal, setRamal] = useState("");
    const [whatsPessoal, setWhatsPessoal] = useState("");
    const [loading, setLoading] = useState(false);
    const [msgAlert, setMsgAlert] = useState("");
    const [open, setOpen] = useState(false);
    const [permissionsModalOpen, setPermissionsModalOpen] = useState(false);
    const [severity, setSeverity] = useState<"success" | "error">("success");
    const { cadastraUsuario, pegaPermissoes, buscaLiderTime, sideBarOpen, setSideBarOpen } = useContext(AuthContext);
    const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
    const [permissions, setPermissions] = useState<IPermission[]>([]);
    const [lideres, setLideres] = useState<IAutocompleteOption[]>([]);
    const [selectedOptionsLideres, setSelectedOptionsLideres] = useState<string | null>(null);
    const [groupedPermissions, setGroupedPermissions] = useState<Record<number, IPermission[]>>({});

    useEffect(() => {
        const organizePermissions = (permissions: IPermission[]) => {
            const menus: Record<number, IPermission[]> = {};
            
            permissions.forEach(permission => {
                if (permission.menuPai === null) {
                    console.log('entrou');
                    
                    menus[permission.id] = []; // Inicializa o menu
                } else {
                    menus[permission.menuPai] = menus[permission.menuPai] || [];
                    menus[permission.menuPai].push(permission);
                }
            });
             delete menus[null as unknown as number]; // Remove se existir
             delete menus[0 as unknown as number]; // Remove se existir
                         
            return menus;
        };

        const fetchPermissions = async () => {
            try {
                const permissionsData: IPermission[] = await pegaPermissoes();
                setPermissions(permissionsData);
                const organized = organizePermissions(permissionsData);
                setGroupedPermissions(organized);
            } catch (error) {
                console.error('Erro ao buscar permissões', error);
            }
        };

        const fetchLider = async () => {
            try {
                const liderData: ILiderUsuario[] = await buscaLiderTime();
                const autocompleteOptions = liderData.map((lider) => ({
                    label: lider.username,
                    id: lider.id.toString(),
                }));
                setLideres(autocompleteOptions);
            } catch (error) {
                console.error('Erro ao buscar líderes', error);
            }
        };

        fetchPermissions();
        fetchLider();

        if (currentUser) {
            setUsername(currentUser.username || "");
            setEmail(currentUser.email || "");
            setAcessoGDocs(currentUser.acesso_GDocs || false);
            setSelectedOptionsLideres(String(currentUser.iD_Lider_Time));
            setEhLiderTime(currentUser.eh_Lider_Time || false);
            setAtualizarADBaseGdocs(currentUser.atualizar_AD_Base_Gdocs || false);
            setAtivo(currentUser.ativo || false);
            setRamal(currentUser.ramal || "");
            setWhatsPessoal(currentUser.whats_Pessoal || "");
            setSelectedPermissions(currentUser.permissions || []);
        }
    }, [currentUser]);

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = () => setOpen(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        if (password !== confirmaSenha) {
            setSeverity("error");
            setMsgAlert("As senhas digitadas não são iguais.");
            setOpen(true);
            setLoading(false);
            return;
        }

        try {
            const usuario: ICadastroUsuario = {
                id: currentUser?.id,
                username,
                email,
                password,
                permissions: selectedPermissions,
                acesso_GDocs: acessoGDocs,
                eh_Lider_Time: ehLiderTime,
                atualizar_AD_Base_Gdocs: atualizarADBaseGdocs,
                iD_Lider_Time: Number(selectedOptionsLideres),
                ativo,
                ramal,
                whats_Pessoal: whatsPessoal,
            };
            await cadastraUsuario(usuario);
            setMsgAlert("Usuário cadastrado com sucesso!");
            setSeverity("success");
            setOpen(true);
            navigate('/ti/usuarios');
        } catch (error) {
            setMsgAlert("Erro ao cadastrar usuário.");
            setSeverity("error");
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handlePermissionChange = (e: React.ChangeEvent<HTMLInputElement>, id: number | undefined) => {
        if (id) {
            setSelectedPermissions(prev =>
                e.target.checked ? [...prev, id] : prev.filter(permissionId => permissionId !== id)
            );
        }
    };

    const contents = loading ? <Loading /> : "";

    const handleSideBar = (opening: boolean) => {
        if(opening)
        {
            if(sideBarOpen) setSideBarOpen(!sideBarOpen);
        } 

        if(!opening)
        {
            console.log('fehando: ', "opening: ",opening, "; sidebar: ", sideBarOpen);
            
            if(!sideBarOpen) setSideBarOpen(!sideBarOpen);
        }
    }

    return (
        <Box sx={{ width: "70%", margin: "0 auto", padding: "2rem", textAlign: "center" }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {msgAlert}
                </Alert>
            </Snackbar>

            <Typography variant="h4" gutterBottom>
                Cadastrar Usuário/Editar Permissões
            </Typography>

            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                noValidate
                onSubmit={handleSubmit}
            >
                <Avatar alt="Foto do Usuário" sx={{ width: 70, height: 70, margin: "0 auto" }} />
                <TextField required label="Nome Usuário" value={username} onChange={(e) => setUsername(e.target.value)} />
                <TextField required label="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <Autocomplete
                    disablePortal
                    options={lideres}
                    value={lideres.find(option => option.id === selectedOptionsLideres) || null}
                    onChange={(e, newValue) => setSelectedOptionsLideres(newValue?.id || null)}
                    renderInput={(params) => <TextField {...params} label="Superior Imediato" />}
                />
                <TextField label="Ramal" value={ramal} onChange={(e) => setRamal(e.target.value)} />
                <TextField label="WhatsApp Pessoal" value={whatsPessoal} onChange={(e) => setWhatsPessoal(e.target.value)} />
                <FormGroup row>
                    <FormControlLabel control={<Checkbox checked={ativo} onChange={(e) => setAtivo(e.target.checked)} />} label="Ativo" />
                    <FormControlLabel control={<Checkbox checked={acessoGDocs} onChange={(e) => setAcessoGDocs(e.target.checked)} />} label="Acesso ao GDocs" />
                    <FormControlLabel control={<Checkbox checked={ehLiderTime} onChange={(e) => setEhLiderTime(e.target.checked)} />} label="É líder de time?" />
                </FormGroup>
                <Button variant="outlined" onClick={() => {setPermissionsModalOpen(true); handleSideBar(true)}}>Editar Permissões</Button>
                <Button type="submit" variant="contained" endIcon={<SendIcon />}>
                    Salvar
                </Button>
            </Box>

            <Modal
                title="Permissões"
                open={permissionsModalOpen}
                onCancel={() => {setPermissionsModalOpen(false); handleSideBar(false);}}
                footer={null} // Remove os botões padrão do footer
                width="80%"
                bodyStyle={{
                    maxHeight: "70vh",
                    overflowY: "auto",
                }}
                getContainer={document.body} // Garante que o Modal seja renderizado fora do layout
                style={{ zIndex: 2000 }} // Define um z-index maior que o sidebar
            >
                <Grid container spacing={4} justifyContent="center">
                    {[0, 1].map((columnIndex) => (
                        <Grid item xs={6} key={columnIndex}>
                            {Object.entries(groupedPermissions)
                                .slice(
                                    columnIndex * Math.ceil(Object.keys(groupedPermissions).length / 2), // Divisão dos menus
                                    (columnIndex + 1) * Math.ceil(Object.keys(groupedPermissions).length / 2)
                                )
                                .map(([menuId, subPermissions]) => {
                                    const menu = permissions.find(p => p.id === parseInt(menuId));
                                    return (
                                        <Box key={menu?.id} sx={{ marginBottom: 2, textAlign: "left" }}>
                                            {/* Menu */}
                                            <Typography style={{ fontWeight: "bold", marginBottom: "8px" }}>
                                                {menu?.descricao}
                                                <Checkbox
                                                    checked={selectedPermissions.includes(menu?.id || 0)}
                                                    onChange={(e) => handlePermissionChange(e, menu?.id)}
                                                    style={{ marginLeft: "8px" }}
                                                />
                                            </Typography>
                                            {/* Submenus */}
                                            {subPermissions.map(subPermission => (
                                                <FormControlLabel
                                                    sx={{ display: 'flex', marginLeft: 2 }}
                                                    key={subPermission.id}
                                                    control={
                                                        <Checkbox
                                                            checked={selectedPermissions.includes(subPermission.id)}
                                                            onChange={(e) => handlePermissionChange(e, subPermission.id)}
                                                        />
                                                    }
                                                    label={subPermission.descricao}
                                                />
                                            ))}
                                        </Box>
                                    );
                                })}
                        </Grid>
                    ))}
                </Grid>
            </Modal>



            {contents}
        </Box>
    );
}

export default CadastroUsuarioPage;
