import HomePage from "../pages/home/HomePage";
import { RouteType } from "./config";
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import CampaignIcon from '@mui/icons-material/Campaign';
import ApartmentIcon from '@mui/icons-material/Apartment';
import StarIcon from '@mui/icons-material/Star';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import SmsIcon from '@mui/icons-material/Sms';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ConstructionIcon from '@mui/icons-material/Construction';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import AssessmentIcon from '@mui/icons-material/Assessment';
import WebIcon from '@mui/icons-material/Web';
import GavelIcon from '@mui/icons-material/Gavel';
import CalculateIcon from '@mui/icons-material/Calculate';
import ShieldIcon from '@mui/icons-material/Shield';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import CakeIcon from '@mui/icons-material/Cake';
import SummarizeIcon from '@mui/icons-material/Summarize';
import WelcomekitPage from "../pages/condominio/WelcomeKitPage";
import ERemessaPage from "../pages/condominio/ERemessa";
import DigifilePage from "../pages/condominio/Digifile";
import CNDPage from "../pages/condominio/CND";
import CondominioPageLayout from "../pages/condominio/CondominioPageLayout";
import ImportarEconomiasPage from "../pages/condominio/ImportarEconomiasPage";
import ImportarInadimplenciaPage from "../pages/condominio/ImportarInadimplencia";
import TIPageLayout from "../pages/TI/TIPageLayout";
import VendasPageLayout from "../pages/Vendas/VendasPageLayout";
import FichaFinanceiraPage from "../pages/Vendas/FichaFinanceiraPage";
import EnviarSMSLoteLayout from "../pages/TI/EnviarSMSLotePage";
import UsuarioPage from "../pages/TI/UsuarioPage";
import CadastroUsuarioPage from "../pages/TI/CadastroUsuario";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import HouseIcon from '@mui/icons-material/House';
import { validarToken } from "../services/api";
import AlterarSenhaPage from "../pages/TI/AlterarSenha";
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import RelatorioIntegradorPage from "../pages/site/RelatorioIntegrador/RelatorioIntegradorPage";
import SitePageLayout from "../pages/site/SitePageLayout";
import CDLPageLayout from "../pages/juridico/CDLPageLayout";
import RelatorioCDLPage from "../pages/juridico/RelatorioCDL/RelatorioCDLPage";
import RelatorioLeadsPage from "../pages/site/RelatorioLeads/RelatorioLeadsPage";
import CalculadoraAlugueisPage from "../pages/alugueis/CalculadoraAlugueis";
import AlugueisPageLayout from "../pages/alugueis/AlugueisPageLayout";
import BoletoInadimplenciaNSPage from "../pages/juridico/BoletoInadimplenciaNSPage";
import LoteAutomacaoPageLayout from "../pages/LoteAutomacao/LoteAutomacaoPageLayout";
import ProcessarArquivoPage from "../pages/LoteAutomacao/ProcessarArquivoPage";
import PesquisarLoteAutomacaoPage from "../pages/LoteAutomacao/PesquisarLoteAutomacaoPage";
import FeriasColaboradorPage from "../pages/TI/FeriasColaboradorPage";
import CadastrarFeriasColaboradorPage from "../pages/TI/CadastrarFeriasColaboradorPage";
import SegurosPageLayout from "../pages/Seguros/SeguroPagesLayout";
import BetalabsPage from "../pages/Seguros/Betalabs";
import BuscarIntegracaoPage from "../pages/Seguros/BuscaIntegracaoBetalabs/BuscaIntegracao";
import ListaAniversariantes from "../pages/marketing/ListaAniversariantes";
import BloqueioInadimplentes from "../pages/condominio/BloqueioInadimplentes";
import RelatorioEmailsAniversario from "../pages/marketing/RelatorioAniversario/RelatorioEmailsAniversario";
import ImportaNumeroSorte from "../pages/Seguros/NumerosDaSorte/ImportarNumeroSortePage";
import ProtocoloCorrespondenciaPage from "../pages/condominio/ProtocoloCorrespondencia";
import RelatorioDesocupacaoPage from "../pages/alugueis/desocupacao/relatorio/RelatorioDesocupacaoPage";
import BloqueioEmail from "../pages/marketing/BloqueioEmail/BloqueioEmail";
import MailLockIcon from '@mui/icons-material/MailLock';
import BuscarClientesPage from "../pages/condominio/BuscarClientes/BuscarClientes";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import RelatorioEmailsOnboarding from "../pages/marketing/RelatorioOnboarding/RelatorioEmailsOnboarding";
import RelatorioDesocupadosPage from "../pages/alugueis/desocupacao/RelatorioDesocupados/RelatorioDesocupadosPage";
import RelatorioEsteiraRenovacao from "../pages/alugueis/RelatorioRenovação/RelatorioEsteiraRenovacao";
import RelatorioErrosRenovacao from "../pages/alugueis/RelatorioErrosRenovacao/RelatorioErrosRenovacao";
import MatrixNineBox from "../pages/rh/MatrixNineBox";
import RHPageLayout from "../pages/rh/RHPageLayout";
import GroupIcon from '@mui/icons-material/Group';
import WidgetsIcon from '@mui/icons-material/Widgets';
import DocumentacaoInativos from "../pages/condominio/DocumentacaoInativos/DocumentacaoInativos";
import TranscricaoPage from "../pages/TI/TranscricaoPage";
/*
function pegarDadosProcessados() {
    let userData = permissaoUsuario();
    const p: IPermissao[] = JSON.parse(userData.data);
    return p;
}*/

interface IPermissao {
    id: number;
    descricao: string;
    menu_Pai: string;
    caminho: string;
}

function hasPermission(state: string, permissao: IPermissao[]) {
    for (let i = 0; i < permissao.length; i = i + 1) {
        if (permissao[i].caminho === state) {
            return true;
        }
    }
    return false;
}

function retornoArray() {

    //Verificar se token e valido
    validarToken();

    const appRoutesV2: RouteType[] = [];
    let lo = localStorage.getItem("permission");

    let p: IPermissao[] = JSON.parse(lo != null ? lo : "{}");

    appRoutesV2.push({
        index: true,
        element: <HomePage />,
        state: "home"
    });


    if (hasPermission('alugueis', p)) {
        const appRoutesCondominio: RouteType[] = [];

        appRoutesCondominio.push({
            path: "/alugueis",
            state: "alugueis",
            element: <AlugueisPageLayout />,
            sidebarProps: {
                displayText: "Aluguéis",
                icon: <HouseIcon />
            }, child: []
        });

        const ch: RouteType[] = [];

        if (hasPermission('alugueis.calculadora.alugueis', p)) {
            ch.push({
                path: "/alugueis/calculadora",
                element: <CalculadoraAlugueisPage />,
                state: "alugueis.calculadora.alugueis",
                sidebarProps: {
                    displayText: "Calculadora",
                    icon: <CalculateIcon />
                }
            });
        }

        if (hasPermission('alugueis.relatoriodesocupacao', p)) {
            ch.push({
                path: "/alugueis/relatoriodesocupacao",
                element: <RelatorioDesocupacaoPage />,
                state: "alugueis.relatoriodesocupacao",
                sidebarProps: {
                    displayText: "Relatório Desocupação",
                    icon: <AssessmentIcon />
                }
            });
        }

        if (hasPermission('alugueis.relatoriodesocupados', p)) {
            ch.push({
                path: "/alugueis/relatoriodesocupados",
                element: <RelatorioDesocupadosPage />,
                state: "alugueis.relatoriodesocupados",
                sidebarProps: {
                    displayText: "Desocupados atualizados imoview",
                    icon: <AssessmentIcon />
                }
            });
        }

        if (hasPermission('alugueis.relatorio.emails.esteira.renovacao', p)) {
            ch.push({
                path: "/alugueis/relatorio/esteirarenovacao",
                element: <RelatorioEsteiraRenovacao />,
                state: "alugueis.relatorio.emails.esteira.renovacao",
                sidebarProps: {
                    displayText: "Relatório Esteira de Renovação",
                    icon: <AssessmentIcon />
                }
            });
        }

        if (hasPermission('alugueis.relatorio.emails.erros.renovacao', p)) {
            ch.push({
                path: "/alugueis/relatorio/errosrenovacao",
                element: <RelatorioErrosRenovacao />,
                state: "alugueis.relatorio.emails.erros.renovacao",
                sidebarProps: {
                    displayText: "Relatório Esteira de Renovação - Erros de Envio",
                    icon: <AssessmentIcon />
                }
            });
        }

        for (let i = 0; i < ch.length; i++) {
            appRoutesCondominio[0].child?.push(ch[i]);
        }

        appRoutesV2.push(appRoutesCondominio[0]);
    }

    if (hasPermission('condominio', p)) {
        const appRoutesCondominio: RouteType[] = [];

        appRoutesCondominio.push({
            path: "/condominio",
            state: "condominio",
            element: <CondominioPageLayout />,
            sidebarProps: {
                displayText: "Condominio",
                icon: <FormatListBulletedOutlinedIcon />
            }, child:[]
        });

        const ch: RouteType[] = [];

        if (hasPermission('condominio.welcomekit', p)) {
            ch.push({
                path: "/condominio/welcomekit",
                element: <WelcomekitPage />,
                state: "condominio.welcomekit",
                sidebarProps: {
                    displayText: "Welcome Kit",
                    icon: <CampaignIcon />
                }
            });
        }

        if (hasPermission('condominio.bloqueio.inadimplentes', p)) {
            ch.push({
                path: "/condominio/bloqueio/inadimplentes",
                element: <BloqueioInadimplentes />,
                state: "condominio.bloqueio.inadimplentes",
                sidebarProps: {
                    displayText: "Bloqueio Inadimplentes",
                    icon: <ApartmentIcon />
                }
            });
        }

        if (hasPermission('condominio.importar.economia', p)) {
            ch.push({
                path: "/condominio/importareconomia",
                element: <ImportarEconomiasPage />,
                state: "condominio.importar.economia",
                sidebarProps: {
                    displayText: "Importar Economia",
                    icon: <ApartmentIcon />
                }
            });
        }

        if (hasPermission('condominio.importar.inadimplencia', p)) {
            ch.push({
                path: "/condominio/importarinadimplencia",
                element: <ImportarInadimplenciaPage />,
                state: "condominio.importar.inadimplencia",
                sidebarProps: {
                    displayText: "Importar Inadimplencia",
                    icon: <ApartmentIcon />
                }
            });
        }

        if (hasPermission('condominio.eremessa', p)) {
            ch.push({
                path: "/condominio/eremessa",
                element: <ERemessaPage />,
                state: "condominio.eremessa",
                sidebarProps: {
                    displayText: "ERemessa",
                    icon: <CampaignIcon />
                }
            });
        }

        if (hasPermission('condominio.digifile', p)) {
            ch.push({
                path: "/condominio/digifile",
                element: <DigifilePage />,
                state: "condominio.digifile",
                sidebarProps: {
                    displayText: "Digifile",
                    icon: <TextSnippetIcon />
                }
            });
        }

        if (hasPermission('condominio.cnd', p)) {
            ch.push({
                path: "/condominio/cnd",
                element: <CNDPage />,
                state: "condominio.cnd",
                sidebarProps: {
                    displayText: "CNDs",
                    icon: <RequestPageIcon />
                }
            });
        }

        if (hasPermission('condominio.protocolo.correspondencia', p)) {
            ch.push({
                path: "/condominio/protocolocorrespondencia",
                element: <ProtocoloCorrespondenciaPage />,
                state: "condominio.protocolo.correspondencia",
                sidebarProps: {
                    displayText: "Protocolo Correspondência",
                    icon: <SummarizeIcon />
                }
            });
        }

        if (hasPermission('condominio.buscar.clientes', p)) {
            ch.push({
                path: "/condominio/buscarclientes",
                element: <BuscarClientesPage />,
                state: "condominio.buscarclientes",
                sidebarProps: {
                    displayText: "Buscar Clientes",
                    icon: <PersonSearchIcon/>
                }
            });
        }

        if (hasPermission('condominio.documentacao.inativos', p)) {
            ch.push({
                path: "/condominio/documentacaoInativos",
                element: <DocumentacaoInativos />,
                state: "condominio.documentacao.inativos",
                sidebarProps: {
                    displayText: "Documentação Inativos",
                    icon: <TextSnippetIcon />
                }
            });
        }
        for (let i = 0; i < ch.length; i++) {
            appRoutesCondominio[0].child?.push(ch[i]);
        }

        appRoutesV2.push(appRoutesCondominio[0]);
    }
    if (hasPermission('marketing', p)) {
        const appRoutesCondominio: RouteType[] = [];

        appRoutesCondominio.push({
            path: "/marketing",
            state: "marketing",
            element: <AlugueisPageLayout />,
            sidebarProps: {
                displayText: "Marketing",
                icon: <LightbulbIcon />
            }, child: []
        });

        const ch: RouteType[] = [];

        if (hasPermission('marketing.aniversario.marketing', p)) {
            ch.push({
                path: "/marketing/ListaAniversariantes",
                element: <ListaAniversariantes />,
                state: "lista.aniversariantes", //não adicionar "marketing" no state do child, pois o react renederiza e ativa o state de TI também, pois interpreta o TI da palavra marketing.
                sidebarProps: {
                    displayText: "Lista de Aniversariantes",
                    icon: <CakeIcon />
                }
            });
        }

        if (hasPermission('marketing.relatorio.email.aniversario', p)) {            
            ch.push({
                path: "/marketing/relatorioemail",
                element: <RelatorioEmailsAniversario />,
                state: "relatorio.email.aniversario",
                sidebarProps: {
                    displayText: "Relatório de emails de aniversário",
                    icon: <AssessmentIcon />
                }
            });
        }

        if (hasPermission('marketing.bloqueio.email', p)) {            
            ch.push({
                path: "/marketing/bloqueioemail",
                element: <BloqueioEmail />,
                state: "bloqueio.email",
                sidebarProps: {
                    displayText: "Bloqueio de emails",
                    icon: <MailLockIcon />
                }
            });
        }

        if (hasPermission('marketing.relatorio.onboarding', p)) {            
            ch.push({
                path: "/marketing/relatorio/onboarding",
                element: <RelatorioEmailsOnboarding />,
                state: "relatorio.onboarding",
                sidebarProps: {
                    displayText: "Relatório de emails Onboarding",
                    icon: <AssessmentIcon />
                }
            });
        }

        for (let i = 0; i < ch.length; i++) {
            appRoutesCondominio[0].child?.push(ch[i]);
        }

        appRoutesV2.push(appRoutesCondominio[0]);
    }

    if (hasPermission('ti', p)) {
        const appRoutesCondominio: RouteType[] = [];

        appRoutesCondominio.push({
            path: "/ti",
            state: "ti",
            element: <TIPageLayout />,
            sidebarProps: {
                displayText: "Utilidades TI",
                icon: <ConstructionIcon />
            }, child: []
        });

        const ch: RouteType[] = [];

        if (hasPermission('ti.enviosms', p)) {
            ch.push({
                path: "/ti/enviosms",
                element: <EnviarSMSLoteLayout />,
                state: "ti.enviosms",
                sidebarProps: {
                    displayText: "Enviar SMS Lote",
                    icon: <SmsIcon />
                }
            });
        }

        if (hasPermission('ti.cadastrar.usuario', p)) {
            ch.push({
                path: "/ti/usuarios",
                element: <UsuarioPage/>,
                state: "ti.usuario",
                sidebarProps: {
                    displayText: "Usuários",
                    icon: <PersonAddAlt1Icon />
                }
            });

            ch.push({
                path: "/ti/usuarios/cadastrar",
                element: <CadastroUsuarioPage/>,
                state: "ti.usuario.cadastrar",
            });
        }

        if (hasPermission('ti.ferias', p)) {
            ch.push({
                path: "/ti/ferias",
                element: <FeriasColaboradorPage/>,
                state: "ti.ferias",
                sidebarProps: {
                    displayText: "Férias Colaborador",
                    icon: <PersonAddAlt1Icon />
                }
            });

            ch.push({
                path: "/ti/ferias/cadastrar",
                element: <CadastrarFeriasColaboradorPage/>,
                state: "ti.ferias.cadastrar",
            });
        }

        if (hasPermission('ti.alterar.senha', p)) {
            ch.push({
                path: "/ti/alterarsenha",
                element: <AlterarSenhaPage/>,
                state: "ti.alterar.senha",
                sidebarProps: {
                    displayText: "Alterar Senha",
                    icon: <EnhancedEncryptionIcon />
                }
            });
        }

        if (hasPermission('ti.transcrever', p)) {
            ch.push({
                path: "/ti/transcrever",
                element: <TranscricaoPage/>,
                state: "ti.transcrever",
                sidebarProps: {
                    displayText: "Transcrever Audio/Video",
                    icon: <EnhancedEncryptionIcon />
                }
            });
        }

        

        // if (hasPermission('ti.editar.permissoes', p)) {
        //     ch.push({
        //         path: "/ti/editarpermissoes",
        //         element: <EditarPermissoesPage/>,
        //         state: "ti.editar.permissoes",
        //         sidebarProps: {
        //             displayText: "Editar Permissões",
        //             icon: <EditNoteIcon />
        //         }
        //     });
        // }

        for (let i = 0; i < ch.length; i++) {
            appRoutesCondominio[0].child?.push(ch[i]);
        }

        appRoutesV2.push(appRoutesCondominio[0]);
    }

    if (hasPermission('vendas', p)) {
        const appRoutesCondominio: RouteType[] = [];

        appRoutesCondominio.push({
            path: "/vendas",
            state: "vendas",
            element: <VendasPageLayout />,
            sidebarProps: {
                displayText: "Vendas",
                icon: <HouseIcon />
            }, child: []
        });

        const ch: RouteType[] = [];

        if (hasPermission('vendas.fichafinanceira', p)) {
            ch.push({
                path: "/vendas/fichafinanceira",
                element: <FichaFinanceiraPage />,
                state: "vendas.fichafinanceira",
                sidebarProps: {
                    displayText: "Ficha Financeira",
                    icon: <RequestQuoteIcon />
                }
            });
        }

        for (let i = 0; i < ch.length; i++) {
            appRoutesCondominio[0].child?.push(ch[i]);
        }

        appRoutesV2.push(appRoutesCondominio[0]);
    }

    if (hasPermission('juridico', p)) {
        const appRoutesCondominio: RouteType[] = [];

        appRoutesCondominio.push({
            path: "/juridico",
            state: "juridico",
            element: <CDLPageLayout />,
            sidebarProps: {
                displayText: "Jurídico",
                icon: <GavelIcon />
            }, child: []
        });

        const ch: RouteType[] = [];

        if (hasPermission('juridico.cdl', p)) {
            ch.push({
                path: "/juridico/cdl",
                element: <RelatorioCDLPage />,
                state: "juridico.cdl",
                sidebarProps: {
                    displayText: "CDL",
                    icon: <AssessmentIcon />
                }
            });
        }

        if (hasPermission('juridico.boletos.inadimplencia', p)) {
            ch.push({
                path: "/juridico/boletosinadimplencia",
                element: <BoletoInadimplenciaNSPage />,
                state: "juridico.boletos.inadimplencia",
                sidebarProps: {
                    displayText: "Boletos Inadimplencia",
                    icon: <AssessmentIcon />
                }
            });
        }

        for (let i = 0; i < ch.length; i++) {
            appRoutesCondominio[0].child?.push(ch[i]);
        }

        appRoutesV2.push(appRoutesCondominio[0]);
    }

    if (hasPermission('site', p)) {
        const appRoutesCondominio: RouteType[] = [];

        appRoutesCondominio.push({
            path: "/site",
            state: "site",
            element: <SitePageLayout />,
            sidebarProps: {
                displayText: "Site",
                icon: <WebIcon />
            }, child: []
        });

        const ch: RouteType[] = [];

        if (hasPermission('site.relatoriointegrador', p)) {
            ch.push({
                path: "/site/relatoriointegrador",
                element: <RelatorioIntegradorPage />,
                state: "site.relatoriointegrador",
                sidebarProps: {
                    displayText: "Relatório Integrador",
                    icon: <AssessmentIcon />
                }
            });
        }

        if (hasPermission('site.relatorioleads', p)) {
            ch.push({
                path: "/site/relatorioleads",
                element: <RelatorioLeadsPage />,
                state: "site.relatorioleads",
                sidebarProps: {
                    displayText: "Relatório Leads",
                    icon: <AssessmentIcon />
                }
            });
        }

        for (let i = 0; i < ch.length; i++) {
            appRoutesCondominio[0].child?.push(ch[i]);
        }

        appRoutesV2.push(appRoutesCondominio[0]);
    }

    if (hasPermission('loteautomacao', p)) {
        const appRoutesCondominio: RouteType[] = [];

        appRoutesCondominio.push({
            path: "/loteautomacao",
            state: "loteautomacao",
            element: <LoteAutomacaoPageLayout />,
            sidebarProps: {
                displayText: "Lote Automacao",
                icon: <AutoModeIcon />
            }, child: []
        });

        const ch: RouteType[] = [];

        if (hasPermission('loteautomacao.processar.arquivo', p)) {
            ch.push({
                path: "/loteautomacao/processararquivo",
                element: <ProcessarArquivoPage />,
                state: "loteautomacao.processar.arquivo",
                sidebarProps: {
                    displayText: "Processar Arquivo",
                    icon: <SmartToyTwoToneIcon />
                }
            });
        }

        if (hasPermission('loteautomacao.pesquisar.lote', p)) {
            ch.push({
                path: "/loteautomacao/pesquisarlote",
                element: <PesquisarLoteAutomacaoPage />,
                state: "loteautomacao.pesquisar.lote",
                sidebarProps: {
                    displayText: "Pesquisar Lote",
                    icon: <CloudSyncIcon />
                }
            });
        }

        for (let i = 0; i < ch.length; i++) {
            appRoutesCondominio[0].child?.push(ch[i]);
        }

        appRoutesV2.push(appRoutesCondominio[0]);
    }
    if (hasPermission('seguros', p)) {        
        const appRoutesSeguros: RouteType[] = [];
        const ch: RouteType[] = [];

        appRoutesSeguros.push({
            path: "/seguros",
            state: "seguros",
            element: <SegurosPageLayout />,
            sidebarProps: {
                displayText: "Seguros",
                icon: <ShieldIcon />
            }, child: []
        });


        if (hasPermission('seguros.betalabs', p)) {
            ch.push({
                path: "/seguros/betalabs",
                element: <BetalabsPage />,
                state: "seguros.betalabs",
                sidebarProps: {
                    displayText: "Betalabs",
                    icon: <AssessmentIcon />
                }
            });
        }

        if (hasPermission('seguros.integracoes.betalabs', p)) {
            ch.push({
                path: "/seguros/integracoesbetalabs",
                element: <BuscarIntegracaoPage />,
                state: "seguros.integracoes.betalabs",
                sidebarProps: {
                    displayText: "Integrações Betalabs",
                    icon: <ContentPasteSearchIcon />
                }
            });
        }

        if (hasPermission('seguros.importar.numeros.da.sorte', p)) {
            ch.push({
                path: "/seguros/numerosdasorte",
                element: <ImportaNumeroSorte />,
                state: "seguros.importar.numeros.da.sorte",
                sidebarProps: {
                    displayText: "Números da Sorte",
                    icon: <StarIcon />
                }
            });
        }
        for (let i = 0; i < ch.length; i++) {
            appRoutesSeguros[0].child?.push(ch[i]);
        }

        appRoutesV2.push(appRoutesSeguros[0]);
    }

    if (hasPermission('rh', p)) {        
        const appRoutesRH: RouteType[] = [];
        const ch: RouteType[] = [];

        appRoutesRH.push({
            path: "/rh",
            state: "rh",
            element: <RHPageLayout />,
            sidebarProps: {
                displayText: "RH",
                icon: <GroupIcon />
            }, child: []
        });


        if (hasPermission('rh.ninebox', p)) {
            ch.push({
                path: "/rh/ninebox",
                element: <MatrixNineBox />,
                state: "rh.ninebox",
                sidebarProps: {
                    displayText: "9Box",
                    icon: <WidgetsIcon />
                }
            });
        }

        for (let i = 0; i < ch.length; i++) {
            appRoutesRH[0].child?.push(ch[i]);
        }

        appRoutesV2.push(appRoutesRH[0]);
    }

    return appRoutesV2;
}

const appRoutesNovo: RouteType[] = retornoArray();

export default appRoutesNovo;